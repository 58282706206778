<template>
  <div class="main-content bg-default">
    <div class="main-content">
        <div class="login-1">
          <div class="container-fluid">
            <div class="row login-box">
              <div class="col-lg-5 align-self-center pad-0 form-section">
                <div class="form-inner">
                  <Spinner :loader="this.globalPageLoader"></Spinner>
                   <Logo />
                  <div class="frontloader"  v-if="siteloader"></div>
                  <Transition name="linear">
                  <router-view />
                 </Transition>
                </div>
              </div>
              <div class="col-lg-7 pad-0 none-992 bg-img" :style="backgroundStyle">            
                <div class="links">
                  <ul class="d-flex p-0 float-right hiwlinksec">                    
                    <li><a class="text-white supportbg" :href="supportLink" :target="supportLinktarget"><img class="mr-2" src="../assets/icons/support.svg" />Support</a></li>
                  </ul>
                </div>                
                <div class="assitance"   v-if="loginContent.phone || loginContent.email">                
                  <ul class="d-flex p-0 text-center align-items-center">
                      <li>
                      <img src="../../src/assets/icons/telephone.svg" alt="questionicon" /><a
                      class="text-white ml-2"                        
                      :href="'tel:'+loginContent.phone"
                      >{{ loginContent.phone }}</a
                      >
                      </li>
                      <li>
                      <img src="../../src/assets/icons/email.svg" alt="groupicon" /><a
                      class="text-white ml-2  text-lowercase"
                      :href="'mailto:'+loginContent.email"
                      >{{ loginContent.email }}</a
                      >
                      </li>
                  </ul>
                </div>    
                <div class="assitance"   v-else>                
                  <ul class="d-flex p-0 text-center align-items-center">
                      <li>
                      <img src="../../src/assets/icons/telephone.svg" alt="questionicon" /><a
                      class="text-white ml-2"                        
                      :href="'tel:'+phone"
                      >{{ phone }}</a
                      >
                      </li>
                      <li>
                      <img src="../../src/assets/icons/email.svg" alt="groupicon" /><a
                      class="text-white ml-2  text-lowercase"
                      :href="'mailto:'+email"
                      >{{ email }}</a
                      >
                      </li>
                  </ul>
                </div>          
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>

</template>

<style scoped>
body {
  background: #fcebea;
  min-height: 100vh;
  display: grid;
  place-items: center;
}

.bar-loader rect {
  transform-origin: bottom;
  transform: scaleY(0);
}
.bar-loader rect:nth-child(even) {
  transform-origin: top;
}
.bar-loader rect:nth-child(1) {
  fill: #008a8a;
  animation: loading-bar-dynamic-1 3s linear infinite forwards;
  transform: scaleY(0);
}
@keyframes loading-bar-dynamic-1 {
  20% {
    transform: scaleY(0);
  }
  26% {
    transform: scaleY(1);
  }
  94% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}
.bar-loader rect:nth-child(2) {
  fill: #009494;
  animation: loading-bar-dynamic-2 3s linear infinite forwards;
  transform: scaleY(0);
}
@keyframes loading-bar-dynamic-2 {
  26% {
    transform: scaleY(0);
  }
  32% {
    transform: scaleY(1);
  }
  88% {
    transform: scaleY(1);
  }
  94% {
    transform: scaleY(0);
  }
}
.bar-loader rect:nth-child(3) {
  fill: #009f9f;
  animation: loading-bar-dynamic-3 3s linear infinite forwards;
  transform: scaleY(0);
}
@keyframes loading-bar-dynamic-3 {
  32% {
    transform: scaleY(0);
  }
  38% {
    transform: scaleY(1);
  }
  82% {
    transform: scaleY(1);
  }
  88% {
    transform: scaleY(0);
  }
}
.bar-loader rect:nth-child(4) {
  fill: #00a9a9;
  animation: loading-bar-dynamic-4 1s linear infinite forwards;
  transform: scaleY(0);
}
@keyframes loading-bar-dynamic-4 {
  38% {
    transform: scaleY(0);
  }
  44% {
    transform: scaleY(1);
  }
  76% {
    transform: scaleY(1);
  }
  82% {
    transform: scaleY(0);
  }
}
.bar-loader rect:nth-child(5) {
  fill: #00b3b3;
  animation: loading-bar-dynamic-5 1s linear infinite forwards;
  transform: scaleY(0);
}
@keyframes loading-bar-dynamic-5 {
  44% {
    transform: scaleY(0);
  }
  50% {
    transform: scaleY(1);
  }
  70% {
    transform: scaleY(1);
  }
  76% {
    transform: scaleY(0);
  }
}
</style>
<script>
import { FadeInOut } from 'vue3-transitions'
import Logo from "../authpages/Logo.vue"
import TextSlider from "../authpages/TextSlider.vue"
import Spinner from "../components/Spinner"
import axios from "axios";

export default {
  components: {
    Logo,
    FadeInOut,
    TextSlider,
    Spinner
  },
  data() {
    return {
      loginContent:'',
      siteloader:false,
      year: new Date().getFullYear(),
      phone: '(844) iSPORT5 / (844) 4776785',
      email: 'help@isportz.co',
      backgroundImage:'bgimgregister.jpg', // Default image path      
      baseImageUrl: '/mms/img/Loginimg/',
      ifAlliance: false,
      supportLink:'',
      supportLinktarget:''
    }
  },
  computed:{
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.baseImageUrl}${this.backgroundImage})`,  
      };
    }
  },
  created(){
    // if(this.$route.name == 'login') {
    //   this.siteloader = true;
    // }
  },
  destroyed(){
    this.siteloader = false;
  },
  methods:{
  loadLoginContents() {
      axios.get(this.basePath + "api/landing_page_section/2/7", {
        headers: this.header,
      })
          .then(function (response) {
            this.loginContent = response.data.data;
            if(this.loginContent == 0) {
              this.landingStaticContents();
            }
          }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    landingStaticContents() {
      axios.get(this.basePath + "api/login-page-cnt/login", {
        headers: this.header,
      }).then(function (response) {
        this.loginContent = response.data.properties;
      }.bind(this))
          .catch(function (error) {
            console.log(error);
          });
    },
    async getLoginimg()
    {
      await axios.get(this.basePath + "api/getProperties/loginImg")
          .then(res => {
            if(res.data.properties) {
              const imageName = res.data.properties;
              if (imageName && typeof imageName === 'string' && imageName.trim() !== '') {
                this.backgroundImage = imageName;
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
    }
  },  
  beforeMount(){
    this.getLoginimg();
  },
  mounted() { 
    var host = window.location.host;       
    if(host.includes("pre-afp") || host.includes("afp") || host.includes("devafp")){
      this.supportLink = 'help-and-support'; 
      this.supportLinktarget ='_self';   
     }  
     else{
      this.supportLink='https://support.isportz.co/portal'
      this.supportLinktarget ='_blank'; 
     }
    this.loadLoginContents();
    axios.get(this.basePath+'api/getProperties/contact_phone')
        .then((res) => {
          if(res.data.properties)
            this.phone = res.data.properties;
        })
        .catch((error) => {

        }).finally(() => {
    });
  axios.get(this.basePath+'api/getProperties/support_contact_email')
      .then((res) => {
        if(res.data.properties)
          this.email = res.data.properties;
      })
      .catch((error) => {

      }).finally(() => {
      });
      
}
};
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
